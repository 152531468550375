import * as React from 'react';
import { Code } from '@sharefiledev/icons';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncSessions } from './ExtensionSlots';
import { setLogger } from './logger';

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());

	piletApi.registerPage('/settings/personal/sessions', () => (
		<AsyncSessions piletApi={piletApi} />
	));
	piletApi.sf.registerLeftSecondaryNavChildComponent({
		href: '/settings/personal/sessions',
		title: () => 'Sessions',
		weight: 4,
		parent: '/settings/personal',
	});

	piletApi.registerExtension(
		'urn:sfextslot:sharefile-appshell:session-mgmt-pilet:user-preferences',
		({ params }) => <AsyncSessions piletApi={piletApi} {...params} />
	);

	registerDevPage(piletApi);
}

function registerDevPage(piletApi: PiletApi) {
	if (process.env.NODE_ENV === 'development') {
		piletApi.sf.registerLeftNavComponent({
			href: '/session-mgmt-dev',
			title: () => 'Sessions',
			icon: () => <Code style={{ fontSize: '24px' }} />,
			weight: 50,
		});

		piletApi.registerPage('/session-mgmt-dev', () => (
			<AsyncSessions piletApi={piletApi} />
		));

		if (typeof piletApi.meta.config.apiOrigin === 'string') {
			const { configureHttp } = require('./AuthHttp');
			const { Configuration } = require('./generated/runtime');
			configureHttp((configuration: any) => {
				configuration.config = new Configuration({
					basePath: `${piletApi.meta.config.apiOrigin}/io/authentication/v1`,
				});
			});
		}
	}
}
